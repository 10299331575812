export default {
  "global": {
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffnen"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein sehr weiser Mann sagte einmal zum Reverend:<br><br>Erinnere dich immer an meine Worte…mit einem Lächeln eroberst du die Welt…<br><br>…die Vision…lasst uns gemeinsam mit diesem Lächeln die Welt erobern…<br><br>…tritt ein in den lächelnden Kreis und verbreite es…<br><br>Als kurze Einleitung moechte wir euch kurz erklaeren, was wir alle gemeinsam bewirken, veraendernund erreichen wollen und koennen.<br><br>Mit !!! WIR !!! (hoffentlich gehörst Du auch bald dazu), meine ich unsere kleine Gemeinschaftlustiger, immer laechelnder und bisschen verrueckter Guys &amp; Girls.<br><br>Es gibt keine Herausforderung, der wir uns nicht stellen..., Menschen wie !!! WIR !!! veraendern dieWelt.<br><br>Wir gehen nach der Lebensphilosophie...mit einem Laecheln schenkst Du Freude, mit einem Laechelneroberst Du Herzen, mit einem Laecheln trocknest Du Traenen...mit einem Laecheln streichelst Du dieSeele!<br><br>In einer Zeit, wo die Welt mit soviel negativen Einfluessen zu kaempfen hat, die der normaleMenschenverstand in keinster Weise verstehen mag, muss es jemand in die Hand nehmen undversuchen eine weltweite Epidemie auszuloesen!<br><br>\nNEEEEIN!! Nicht was man sonst von einer Epidemie denkt...nichts mit Schmerz, Husten undSchnupfen, Pocken oder wo dir die Genitalien jucken und spaeter abfallen!!!<br>Gegen diese Epidemie wirst du nicht gezwungen dich impfen zu lassen…<br><br>\nEine weltweite Epidemie der Freude, des Laechelns und auch der positiven Einstellung, das allesirgendwann im Leben einen positiven Aspekt haben wird.<br>Sei dabei und tritt ein in den laechelden Kreis!!!"])}
  },
  "title": {
    "founder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Founder des smiling circle"])},
    "nfts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFTs des smiling circle"])}
  },
  "language": {
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Englisch"])}
  },
  "nft": {
    "1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test NFT 1"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."])}
    },
    "2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test NFT 2"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."])}
    },
    "3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test NFT 3"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."])}
    },
    "4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test NFT 4"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."])}
    },
    "5": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test NFT 5"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."])}
    }
  },
  "team": {
    "1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test Team 1"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."])}
    },
    "2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test Team 2"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."])}
    },
    "3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test Team 3"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."])}
    }
  }
}