export default {
  "global": {
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A very wise man once said to the Reverend:<br><br>Remember my words...with a smile you conquer the world...<br><br>...the vision...let&#39;s conquer the world together with this smile...<br><br>...join the smiling circle and spread the word...<br><br>As a brief introduction, we would like to share our thoughts about  what we can all change and achievetogether.<br><br>With !!! WE !!! (hopefully you will soon be one of them/ us), I mean our little community of funny,always smiling and a bit crazy Guys&amp;Girls.<br><br>There is no challenge we are afraid of..., people like!!! US !!! change the world/ make the world abetter place with our actions.<br><br>We follow the philosophy of life... with a smile you give joy, with a smile you conquer hearts, with asmile you dry tears... with a smile you caress the soul!<br><br>At a time when the world is dealing with so many negativity that common sense cannot understand atall, someone has to take the initiative and try to start a worldwide epidemic!<br><br>NOOO!! Not what you usually think of an epidemic...nothing with pain, cough and runny nose,smallpox or where your genitals itch and later fall off!!!<br>!!! For this epidemic there is no vaccination or medicine required !!!<br><br>A worldwide epidemic of joy, smiles and also the positive attitude that everything will have a positiveaspect at some point in life.<br><br>If these are things that resonate with you.<br><br>Be there and join the smiling circle!!!"])}
  },
  "title": {
    "founder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Founder of the smiling circle"])},
    "nfts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFTs of the smiling circle"])}
  },
  "language": {
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])}
  },
  "nft": {
    "1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test NFT 1"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."])}
    },
    "2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test NFT 2"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."])}
    },
    "3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test NFT 3"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."])}
    },
    "4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test NFT 4"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."])}
    },
    "5": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test NFT 5"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."])}
    }
  },
  "team": {
    "1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test Team 1"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."])}
    },
    "2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test Team 2"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."])}
    },
    "3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test Team 3"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."])}
    }
  }
}