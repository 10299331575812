<template>
    <footer class="footer bg-dark">
        <div class="container-fluid">
            <ul class="footer-nav d-flex flex-column flex-lg-row justify-content-evenly">
                <li class="footer-item">
                    <a class="nav-link" aria-current="page" href="#">AGBs</a>
                </li>
                <li class="footer-item">
                    <a class="nav-link" href="#">Datenschutz</a>
                </li>
                <li class="footer-item">
                    <a class="nav-link" href="#">Disabled</a>
                </li>
            </ul>
        </div>
    </footer>
</template>

<script setup lang="ts">
</script>
